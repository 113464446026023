import devICon1 from "../src/assets/icons/dev-icon-1.svg";
import devICon2 from "../src/assets/icons/dev-icon-2.svg";
import vector2 from "../src/assets/icons/Vector2.svg";
import googlePay from "../src/assets/icons/google-pay.svg";

const payload = [
  {
    topIcon: devICon1,
    title: "C’Gate USSD",
    content:
      "This feature is the first in the industry. It will enable customers \n" +
      "to use their USSD PIN to authorize POS or WEB transaction.",
    bottomIcon: vector2,
    footerContent: "See Documentation",
    link: "/developer/docs/cgateussd",
  },
  {
    topIcon: devICon2,
    title: "Bank Link",
    content:
      "Bank Link facilitates online payments via bank fund transfers. Customers are required to choose from a list of available banks.",
    bottomIcon: vector2,
    footerContent: "See Documentation",
    link: "/developer/docs/pay-with-bank-transfer",
  },
  {
    topIcon: devICon2,
    title: "CoralPay Payment Gateway",
    content:
      "Our Payment Gateway aggregates all our payment solutions in one place, enabling customers to have various payment options at merchant’s checkout point",
    bottomIcon: vector2,
    footerContent: "See Documentation",
    link: "/developer/docs/payment-gateway",
  },
  {
    topIcon: devICon1,
    title: "CoralPay VAS",
    content:
      "The CoralPay VAS is a secure interface for vending value for different Billers",
    bottomIcon: vector2,
    footerContent: "See Documentation",
    link: "/developer/docs/vas",
  },
  {
    topIcon: devICon2,
    title: "CoralPay Card APIs",
    content:
      "The OctoPlus Payment Gateway is a secure interface designed by CoralPay for card" +
      "processing and has the capabilities to process payment on behalf of merchant who " +
      "wants to accept card from their interface",
    bottomIcon: vector2,
    footerContent: "See Documentation",
    link: "/developer/docs/card-api",
  },
  {
    topIcon: devICon2,
    title: "Fast Channel",
    content:
      "FastChannel is designed to facilitate single and batch transfer of funds from a designated account to other account(s) in a very fast and seamless approach. The service also gets the status of the posted transactions.",
    bottomIcon: vector2,
    footerContent: "See Documentation",
    link: "/developer/docs/fast-channel",
  },
  {
    topIcon: devICon1,
    title: "CoralPay InterBank Service",
    content:
      "This is a payment service used by banks and other financial institutions to carryout real-time money transfer for customers and businesses.",
    bottomIcon: vector2,
    footerContent: "Contact Us",
    link: "/contact-us",
  },
  {
    topIcon: devICon2,
    title: "Account Connect",
    content:
      "AccountConnect facilitates online payments via one-time payment links. Merchants select a processor from a processor list and complete the transaction using the exact amount displayed on the generated payment link for the chosen processor",
    bottomIcon: vector2,
    footerContent: "See Documentation",
    link: "/developer/docs/account-connect",
  },
  {
    topIcon: googlePay,
    title: "Google Pay",
    content: `Google Pay ™ is a digital wallet and payment platform that allows users to make 
secure online and in-store payments using their smartphones, tablets, and 
smartwatches. It integrates with Google services and supports credit and debit 
cards.`,
    bottomIcon: vector2,
    footerContent: "See Documentation",
    link: "/developer/docs/google-pay",
  },
];

export default payload;
