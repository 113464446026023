import {
  Box,
  Button,
  Grid2,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import {
  darcula,
  a11yDark,
} from "react-syntax-highlighter/dist/esm/styles/prism";
// import DotNetContent from "./dotnet/DotNetContent";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import { scrollToTop } from "../../../components/helper";
// import JavascriptContent from "./javascript/JavascriptContent";
// import JavaContent from "./java";
import googlePayAccount1 from "../../../assets/googlepay-img-1.png";
import googlePayAccount2 from "../../../assets/googlepay-img-2.png";

const GooglePayDocumentation = () => {
  const [javascript, setJavascript] = useState(false);
  const [dotnet, setDotnet] = useState(true);
  const [ceeSharp, setCeeSharp] = useState(false);
  const navigate = useNavigate();

  const apiSpec = useRef(null);
  const snippets = useRef(null);

  const scrollToSection = (ref) => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleJavascriptContent = () => {
    setJavascript((prev) => !prev);
    setDotnet(false);
    setCeeSharp(false);
  };
  const handleDotnetContent = () => {
    setJavascript(false);
    setDotnet((prev) => !prev);
    setCeeSharp(false);
  };
  const handleCeeSharpContent = () => {
    setJavascript(false);
    setDotnet(false);
    setCeeSharp((prev) => !prev);
  };

  useEffect(() => {
    scrollToTop();
  }, []);

  const scriptTag = `
  https://testdev.coralpay.com:5000/FastChannel/api/Authentication
  `;

  const requestPayload = `
  {
      "googleData": {
        // Google Pay data object (details provided by Google Pay)
      },
      "metaData": "sCzB3afgrgrrhtjtehrssggewwrhrsbsniwoqoooq"
  }

  `;

  const responsePayload = `
{
    "responseHeader": {
        "responseCode": "00",
        "responseMessage": "Successful",
        “NavigatingUrl”: “https://...”
    }
}

  `;
  const singlePostUrl = `
  https://testdev.coralpay.com:5000/FastChannel/api/SinglePost
  `;

  const singlePostRequestPayload = `
{
  "traceId": "100992921107213",
  "signature": "81a657c554b654f54441a776c",
  "timestamp": "1602211123",
  "timeStamp": 1694660027,
  "transactionDetails": {
    "creditAccount": "1780004070",
    "creditAccountName": "Test Name",
    "creditBankCode": "999998",
    "narration": "Test narration",
    "amount": 3000.0
  }
}
`;
  const singlePostResponsePayload = `
{
  "responseHeader": {
    "responseCode": "00",
    "responseMessage": "Success"
  },
  "traceId": "1002222222222220008",
  "batchId": "20230914035415758080",
  "amountDebited": 3010.75,
  "transactionAmount": 3000,
  "transactionCharge": 10.0,
  "vat": 0.75
}
`;

  const batchPostUrl = `
https://testdev.coralpay.com:5000/FastChannel/api/BatchPost
`;

  const batchPostRequestPayload = `
{
  "traceId": "1009929211072",
  "totalAmount": 6000.0,
  "signature": "0928uymd0dkq445553222pl9",
  "timestamp": "1602211123",
  "transactionList": [
    {
      "creditAccount": "1234567890",
      "creditAccountName": "Test Name",
      "creditBankCode": "737",
      "narration": "Monthly payment",
      "amount": 2000.0,
      "itemTraceId": "10102929291"
    },
    {
      "creditAccount": "1234567891",
      "creditAccountName": "Test Name",
      "creditBankCode": "966",
      "narration": "Monthly payment",
      "amount": 3000.0,
      "itemTraceId": "10102929292"
    },
    {
      "creditAccount": "1234567898",
      "creditAccountName": "Test Name",
      "creditBankCode": "737",
      "narration": "Monthly payment",
      "amount": 1000.0,
      "itemTraceId": "10102929299"
    }
  ]
}

`;

  const batchPostResponsePayload = `
{
  "responseHeader": {
    "responseCode": "00",
    "responseMessage": "Successful"
  },
  "batchId": "BCT112234553",
  "traceId": "1009929211072",
  "amountDebited": 6060.0,
  "transactionAmount": 6000.0,
  "transactionCharge": 60.0
}
`;

  const nameEnquiryUrl = `
https://testdev.coralpay.com:5000/FastChannel/api/NameEnquiry
`;

  const nameEnquiryRequestPayload = `
{
  "traceId": "2024041134453533211",
  "signature": "1ac221676bc2a33837ac33baa22313",
  "timeStamp": 1689798324,
  "enquiryDetails": {
    "bankCode": "737",
    "accountNumber": "0114547784"
  }
}

`;

  const nameEnquiryResponsePayload = `
{
  "responseHeader": {
    "responseCode": "00",
    "responseMessage": "Successful"
  },
  "traceId": "2024041134453533211",
  "accountName": "Chinedu Musa Ayo",
  "accountNumber": "0114547784",
  "bankCode": "737"
}
`;

  const callBackUrl = `
https://testdev.coralpay.com:5000/FastChannel/api/BatchPost
`;

  const callBackRequestPayload = `
{
  "ResponseCode": "00",
  "ResponseMessage": "Successful",
  "CreditAccount": "1780004070",
  "CreditAccountName": "Nathan Sola",
  "CreditBankCode": "999998",
  "Narration": "Payment for cable",
  "Amount": 8000.0,
  "Charge": 10.0,
  "ItemBatchId": "202201160550030256707",
  "ItemTraceId": "10102929298",
  "TraceId": "1000000100003",
  "MerchantId": "MS1000001"
}

`;

  const callBackResponsePayload = `
{
  "responseCode": "00",
  "responseMessage": "Successful"
}
`;

  const invokeUrl = `
  https://testdev.coralpay.com/VergeTest/api/v1/Invokepayment
  `;
  const invokePaymentRequestPayload = `
    { 
      "RequestHeader": 
      { "MerchantId": "10990MTE0992", 
        "TimeStamp": "0123456789", 
        "Signature": "0928uymd0dkq445553222pl9"
      }, 
      "Customer": 
     { 
        "Email": "babavoss@see.com", 
        "Name": "Baba Voss", 
        "Phone": "002200302092" 
      }, 
      "Customization": 
      { 
      "LogoUrl": "http://sampleurl.com", 
       "Title": "Watermarks Limited", 
       "Description": "Service Payment"
      }, 
      "MetaData": 
        { 
          "Data1": "sample string 1", 
          "Data2": "sample string 2", 
          "Data3": "sample string 3" 
        }, 
         "TraceId": "9900990285", 
         "Amount": 2000.00, 
         "Currency": "NGN", 
         "FeeBearer": "M", 
         "ReturnUrl": "http://samplereturnurl.com/status" 
  
        }
  `;

  const invokePaymentResponsePayload = `{
      "ResponseHeader": {
      "ResponseCode": "00",
      "ResponseMessage": "Successful",
      "TimeStamp": 0123456789,
      "Signature": "120dksl3l3lld303e3k3mjmf36k6j3h"
      },
      "TransactionId": "029918891988381",
      "TraceId": "9900990285",
      "PayPageLink": "https://coralpay.ng/payment/00998833",
      "MetaData": {
      "Data1": "sample string 1",
      "Data2": "sample string 2",
      "Data3": "sample string 3"
      
      }
  }
      `;

  const transactionQueryUrl = `
      https://testdev.coralpay.com:5000/FastChannel/api/TransactionQuery
      `;

  const transactionQueryRequestPayload = `
{
  "traceId": "20240411344535332122",
  "itemTraceId": "10102929292",
  "signature": "c2aa3461676bc2a33837ac33baa22313",
  "timeStamp": 165478546
}
      `;
  const transactionQueryResponsePayload = `
{
  "responseHeader": {
    "merchantId": "string",
    "batchId": " BCT112234543",
    "responseCode": "00",
    "responseMessage": "Successful",
    "amountDebited": 6060.0,
    "traceId": "20240411344535332122",
    "transactionAmount": 6000.0,
    "transactionCharge": 60.0
  },
  "transactionList": [
    {
      "responseCode": "00",
      "responseMessage": "Successful",
      "itemTraceId": "ITM202400111233",
      "creditAccount": "1001245789",
      "creditAccountName": "Dayo Smith",
      "creditBankCode": "string",
      "narration": "Weekly Payment",
      "amount": 6000.0,
      "charge": 60.0
    }
  ]
}
      `;

  return (
    <div>
      <div className="developer-wrapper">
        <div className="developer-wrapper-overlay">
          <div className="developer-wrapper-overlay-inner">
            <h3>GOOGLE PAY</h3>
            <p>
              Google Pay ™ is a digital wallet and payment platform that allows
              users to make secure online and in-store payments using their
              smartphones, tablets, and smartwatches. It integrates with Google
              services and supports credit and debit cards.
            </p>
            <br />
            <br />
            {/*<div style={{display:'flex'}} >*/}
            {/*    <Button message="API Dashboard" bgColor="#7B3F63" bgText="#FFFFFF" />*/}
            {/*    <Button  message="Download Document" bgColor="#EFEFEF" bgText="#3A3A3A"/>*/}
            {/*</div>*/}
          </div>
        </div>
      </div>
      <div className="doc-div-parent-doc">
        <div
          className="docNav"
          style={{ paddingTop: "2rem", position: "sticky", top: "0" }}
        >
          {/* <h3>Documentation</h3> */}
          <Box sx={{ paddingBottom: "2rem" }}>
            <Button
              sx={{
                backgroundColor: "#083857",
                fontWeight: "550",
                color: "#fff",
              }}
              onClick={() => navigate("/developer#services")}
            >
              BACK TO DEVELOPER'S HOMEPAGE
            </Button>
          </Box>
          <nav>
            <List
              sx={{
                border: "1px solid #083857",
                borderRadius: "5px",
              }}
            >
              <ListItem disablePadding>
                {/* <a href="#apiSpec"> */}
                <ListItemButton onClick={() => scrollToSection(apiSpec)}>
                  <ListItemIcon>{/* <InboxIcon /> */}</ListItemIcon>
                  <ListItemText primary="API Specifications" />
                </ListItemButton>
                {/* </a> */}
              </ListItem>
              <ListItem disablePadding>
                {/* <a href="#snippets"> */}
                <ListItemButton onClick={() => scrollToSection(snippets)}>
                  <ListItemIcon>{/* <DraftsIcon /> */}</ListItemIcon>
                  <ListItemText primary="Libraries & Code Snippets" />
                </ListItemButton>
                {/* </a> */}
              </ListItem>
            </List>
          </nav>
          {/* <ul>
              <a href={"/developer/docs/cgateussd"}>
                {" "}
                <li>C’Gate USSD</li>
              </a>
              <a href={"/developer/docs/pay-with-bank-transfer"}>
                {" "}
                <li>Pay With Bank Transfer</li>
              </a>
              <a href={"/developer/docs/payment-gateway"}>
                {" "}
                <li>CoralPay Payment Gateway</li>
              </a>
              <a href={"/developer/docs/vas"}>
                {" "}
                <li>CoralPay VAS</li>
              </a>
              <a href={"/developer/docs/card-api"}>
                {" "}
                <li>CoralPay Card APIs</li>
              </a>
            </ul> */}
        </div>

        <div className="doc-div-doc" ref={apiSpec}>
          <React.Fragment id="apiSpec">
            <h3>Features & Benefits</h3>
            <p className="mt-2 small">
              1. <b>Online and In-App Integration:</b> Seamlessly integrated
              into apps and websites for smooth online purchases.
            </p>
            <p className="mt-2 small">
              2. <b>Cross-Platform Compatibility:</b> Works on Android, iOS, and
              wearables.
            </p>
            <p className="mt-2 small">
              3. <b>Enhanced Security:</b> Tokenization protects card details by
              replacing them with unique codes during transactions.
            </p>
            <p className="mt-2 small">
              4. <b>Easy Setup:</b> Simple and fast onboarding process for both
              businesses and customers.
            </p>
            <br />
            <h3>Implementations Details</h3>
            <p>
              This section outlines the technical integration details for
              merchants using Google Pay™ via CoralPay.
            </p>
            <p>
              For additional information on Google Pay™, click the links below
              to learn more:
            </p>
            <p>
              1.{" "}
              <a
                style={{ color: "blue" }}
                href="https://developers.google.com/pay/api/web/overview"
              >
                https://developers.google.com/pay/api/web/overview
              </a>
            </p>
            <p>
              2.{" "}
              <a
                style={{ color: "blue" }}
                href="https://developers.google.com/pay/api/web/guides/test-and-deploy/integration-checklist"
              >
                https://developers.google.com/pay/api/web/guides/test-and-deploy/integration-checklist
              </a>
            </p>
            <p>
              3.{" "}
              <a
                style={{ color: "blue" }}
                href="https://developers.google.com/pay/api/web/guides/brand-guidelines"
              >
                https://developers.google.com/pay/api/web/guides/brand-guidelines
              </a>
            </p>

            <p className="mt-2 small">
              Merchants who have already integrated with our payment gateway
              <a
                href="/developer/docs/payment-gateway"
                style={{ color: "blue" }}
              >
                {" "}
                Verge
              </a>{" "}
              do not require additional integration to access the Google Pay™
              payment option.
            </p>

            <p>
              <b>To use Google Pay on Verge:</b>{" "}
            </p>
            <p>1. Visit verge by initiating payment via your website or app.</p>
            <p>
              2. You will be redirected to the verge page, where you will be
              prompted to select a preferred payment option.
            </p>
            <p>3. Select Google Pay™ as your preferred payment option.</p>

            <h3>NOTE</h3>
            <p style={{ color: "#FDCC00", fontWeight: 550, fontSize: "1em" }}>
              You must have a Google Pay™ account set up on your Android device
              to use Google Pay on Verge.
            </p>
            <br />
            <img
              src={googlePayAccount1}
              alt="googlepay-account-1"
              width={200}
              height={600}
            />
            <br />
            <p>
              4. Click the button below to make payment using your saved card or
              add a new card. Then, follow the steps to complete payment.
            </p>
            <br />
            <img
              src={googlePayAccount2}
              alt="googlepay-account-2"
              width={600}
              height={600}
            />
            <br />
            <p>
              <b>To use Google Pay as a Merchant who is not on Verge:</b>{" "}
            </p>
            <p>
              This API enables merchants not onboarded on Verge to process
              Google Pay transactions through CoralPay. The payload includes
              encrypted data gotten from Google and an encrypted transaction
              metadata for security.
            </p>
            <p>
              <b>Note:</b> The metaData field contains sensitive transaction
              information, including transactionId and CVV, encrypted using AES.
              CoralPay will provide the AES encryption key to each merchant
              during onboarding.
            </p>
            <br />

            <h3>API Endpoint</h3>
            <table>
              <tr>
                <td>URL</td>
                <td>To be provided during onboarding.</td>
              </tr>
              <tr>
                <td>Method</td>
                <td>POST</td>
              </tr>
              <tr>
                <td>CONTENT-TYPE</td>
                <td>application/json</td>
              </tr>
              <tr>
                <td>REQUEST-PAYLOAD</td>
                <td>
                  <SyntaxHighlighter language="javascript" style={a11yDark}>
                    {requestPayload}
                  </SyntaxHighlighter>
                </td>
              </tr>
              {/* <tr>
                <td>RESPONSE-PAYLOAD</td>
                <td>
                  <SyntaxHighlighter language="javascript" style={a11yDark}>
                    {authResponse}
                  </SyntaxHighlighter>
                </td>
              </tr> */}
            </table>
            <br />

            <h3>Request Field Descriptions</h3>
            <table>
              <td>
                <b>Field</b>
              </td>
              <td>
                <b>Type</b>
              </td>
              <td>
                <b>Description</b>
              </td>
              <tr>
                <td>googleData</td>
                <td>Object</td>
                <td>Google Pay data payload </td>
              </tr>
              <tr>
                <td>metaData</td>
                <td>String</td>
                <td>
                  AES encrypted string containing sensitive metadata, including:
                  <br />
                  <p>1. TransactionId</p>
                  <p>2. CVV</p>
                </td>
              </tr>
            </table>
            <p>
              The AES encryption key will be provided by CoralPay upon
              onboarding.
            </p>
            <table>
              <tr>
                <td>RESPONSE-PAYLOAD</td>
                <td>
                  <SyntaxHighlighter language="javascript" style={a11yDark}>
                    {responsePayload}
                  </SyntaxHighlighter>
                </td>
              </tr>
            </table>
            <br />

            <h3>Response Field Descriptions</h3>
            <table>
              <tr>
                <td>
                  <b>Field</b>
                </td>
                <td>
                  <b>Type</b>
                </td>
                <td>
                  <b>Description</b>
                </td>
              </tr>
              <tr>
                <td>responseCode</td>
                <td>String</td>
                <td>Transaction status code.</td>
              </tr>
              <tr>
                <td>responseMessage</td>
                <td>String</td>
                <td>Descriptive message of the transaction status.</td>
              </tr>
              <tr>
                <td>NavigatingUrl</td>
                <td>String</td>
                <td>
                  This URL directs the merchant (or user's browser) to the 3D
                  Secure (3DS) authentication page. On this page, the user
                  completes the transaction by verifying their identity,
                  typically through an OTP or biometric authentication provided
                  by their bank. After successful authentication, the
                  transaction process continues based on the 3DS result.
                </td>
              </tr>
            </table>
            <br />
            <h3>Gateway and GatewayMerchantID Values</h3>
            <p>
              Merchants must specify the following values when configuring
              Google Pay™:
            </p>
            <table>
              <tr>
                <td>
                  <b>Field</b>
                </td>
                <td>
                  <b>Value</b>
                </td>
              </tr>
              <tr>
                <td>gateway</td>
                <td>coralpay</td>
              </tr>
              <tr>
                <td>gatewayMerchantId</td>
                <td>(Provided during onboarding)</td>
              </tr>
            </table>
            <p>
              gatewayMerchantId is a unique identifier CoralPay assigns each
              merchant during onboarding.
            </p>
            <br />
            <h3>Supported Card Network</h3>
            <br />

            <table>
              <th>Card Network</th>
              <th>Supported Banks</th>

              <tr>
                <td>MasterCard</td>
                <td>All Nigeria Banks</td>
              </tr>
              {/* <tr>
                <td>VERVE</td>
                <td>All Nigeria Banks</td>
              </tr> */}
              <tr>
                <td>VISA</td>
                <td>All Banks</td>
              </tr>
            </table>
          </React.Fragment>

          <br />

          <hr />

          <Box sx={{ width: "100%", paddingTop: "2rem" }} ref={snippets}>
            <Grid2
              container
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Grid2>
                <Typography
                  sx={{
                    color: "#7f3d64",
                    fontSize: "1.2em",
                    fontWeight: "800",
                  }}
                >
                  Libraries & Code Snippets
                </Typography>
              </Grid2>
              <Grid2 sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Button onClick={scrollToTop} sx={{ color: "#7f3d64" }}>
                  Back to the top{" "}
                  <KeyboardDoubleArrowUpIcon
                    sx={{ fontSize: 30, color: "#7f3d64" }}
                  />
                </Button>
              </Grid2>
            </Grid2>

            <Grid2
              container
              sx={{
                display: "flex",
                alignItems: "center",
                padding: "1.5rem 0 1.5rem 0",
              }}
            >
              {/* <div style={{ marginRight: "1rem" }}>
                <button
                  style={{
                    border: javascript ? "none" : "1px solid #242424",
                    borderRadius: "10px",
                    fontWeight: 550,
                    padding: "1rem",
                    color: javascript ? "#fff" : "#242424",
                    backgroundColor: javascript ? "#242424" : "#fff",
                  }}
                  onClick={handleJavascriptContent}
                >
                  JAVASCRIPT
                </button>
              </div> */}
              {/* <div style={{ marginRight: "1rem" }}>
                <button
                  style={{
                    border: dotnet ? "none" : "1px solid #242424",
                    borderRadius: "10px",
                    fontWeight: 550,
                    padding: "1rem",
                    color: dotnet ? "#fff" : "#242424",
                    backgroundColor: dotnet ? "#242424" : "#fff",
                  }}
                  onClick={handleDotnetContent}
                >
                  .NET
                </button>
              </div> */}
              {/* <div>
                <button
                  style={{
                    border: ceeSharp ? "none" : "1px solid #242424",
                    borderRadius: "10px",
                    fontWeight: 550,
                    padding: "1rem",
                    color: ceeSharp ? "#fff" : "#242424",
                    backgroundColor: ceeSharp ? "#242424" : "#fff",
                  }}
                  onClick={handleCeeSharpContent}
                >
                  JAVA
                </button>
              </div> */}
            </Grid2>

            <div>
              {/* {javascript && <JavascriptContent />} */}
              {/* {dotnet && <DotNetContent />} */}
              {/* {ceeSharp && <JavaContent />} */}
            </div>
          </Box>
        </div>
      </div>
    </div>
  );
};

export default GooglePayDocumentation;
