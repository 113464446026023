import React from "react";
import payload from "../devlopersDocumentations";

import {
  Box,
  Card,
  CardActions,
  CardContent,
  Button,
  Typography,
  Stack,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import Filter1Icon from "@mui/icons-material/Filter1";
import TrendingFlatIcon from "@mui/icons-material/TrendingFlat";
import { useNavigate } from "react-router-dom";

const Card3 = () => {
  const navigate = useNavigate();
  // const card3component = payload.map((i, index) => {
  return (
    <Box sx={{ width: "100%", display: "flex" }}>
      <Grid
        container
        spacing={2}
        sx={{ display: "flex", width: "100%", paddingBottom: "2rem" }}
      >
        {payload.map((cardData) => {
          const { content, link, title, bottomIcon, topIcon, footerContent } =
            cardData;

          console.log("");
          return (
            <Grid size={{ xs: 12, sm: 6, md: 4, lg: 4 }} key={title}>
              <Card
                sx={{
                  padding: "1.5rem",
                  height: "350px",
                  "&:hover": {
                    background: "#EFE9EE",
                  },
                }}
              >
                <CardContent>
                  <Stack>
                    <img src={topIcon} height={50} width={50} />
                    <Typography
                      sx={{
                        color: "#083857",
                        fontSize: "1.3em",
                        fontWeight: "800",
                        padding: "1rem 0 1rem 0",
                      }}
                    >
                      {title}
                    </Typography>
                    <Typography sx={{ fontSize: "0.8em" }}>
                      {content}
                    </Typography>
                  </Stack>
                </CardContent>
                <CardActions
                  // sx={{
                  //   display:
                  //     title !== "CoralPay InterBank Service" ? "flex" : "none",
                  // }}
                >
                  <Button
                    size="small"
                    sx={{ color: "#7e315f", fontWeight: "550" }}
                    endIcon={<TrendingFlatIcon sx={{ fontSize: 30 }} />}
                    onClick={() => navigate(`${link}`)}
                    disabled={title === "Google Pay"}
                  >
                    {footerContent}
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          );
        })}
      </Grid>

      {/* <Grid container spacing={2} sx={{ display: "flex", width: "100%" }}>
        {transformedArr?.map((services) => {
          const { id, icon, iconBg, cardColor, cardHoverColor, cardName } =
            services;
          return (
            <Grid
              item
              xs={6}
              sm={4}
              md={3}
              lg={3}
              key={id}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Stack
                sx={{
                  background: `${cardColor}`,
                  display: "flex",
                  justifyContent: "center",
                  width: "250px",
                  height: "135px",
                  padding: "1rem",
                  borderRadius: "10px",
                  cursor: "pointer",
                  "&:hover": {
                    background: `${cardHoverColor}`,
                  },
                }}
                onClick={() => {
                  // const pageTitle = cardName?.toLowerCase();
                  navigate(`/user/billers-category`);
                  secureLocalStorage.setItem("pageTitle", cardName);
                }}
              >
                <Grid
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Grid
                    sx={{
                      background: `${iconBg}`,
                      padding: "0.5rem",
                      display: "flex",
                      justifyContent: "center",
                      borderRadius: "5px",
                      width: "40px",
                      height: "40px",
                    }}
                  >
                    {icon}
                  </Grid>
                  <Grid sx={{ margin: "1rem 0 0 0" }}>
                    <Typography
                      sx={{
                        textAlign: "center",
                        fontSize: {
                          xs: "0.7em",
                          sm: "0.9em",
                          md: "1em",
                          lg: "1em",
                        },
                      }}
                    >
                      {cardName}
                    </Typography>
                  </Grid>
                </Grid>
              </Stack>
            </Grid>
          );
        })}
      </Grid> */}

      {/* <div className="card3">
          <div className="card-img3"></div>
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              height: "100%",
              flexDirection: "column",
              justifyContent: "space-evenly",
            }}
          >
            <div className="">
              <img src={i.topIcon} alt="back" />
              <h3 className="card-3-h3">{i.title}</h3>
              <br />
              <p>{i.content}</p>
            </div>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "flex-start",
              }}
            >
              <img src={i.bottomIcon} alt="back" />
              <a href={i.link}>
                {" "}
                <span className="ml-3" style={{ color: "#733158" }}>
                  {i.footerContent}
                </span>
              </a>
            </div>
          </div>
        </div> */}
    </Box>
  );
  // });

  // return [card3component];
};

export default Card3;
